.Dungeon {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.Dungeon p {
  position: absolute;
  z-index: 1;
}

.Dungeon canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  /* mix-blend-mode: multiply; */
}

.Dungeon img {
  display: none;
}
