body {
    background-color: black;
    color: #ddd;
}

a {
    color: rgb(245, 163, 41);
}

a:hover {
    color: rgb(189, 125, 28);
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.Home {
    margin: 2rem;
}
