@font-face {
    font-family: "Cinzel Regular";
    src: url("../fonts/Cinzel-Regular.woff2");
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
